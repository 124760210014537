export default {
    questions: [
        {
            name: "speech",
            type: "dropdown",
            title: { "default": "Would you be willing to say something short about love during our ceremony?", "sr": "Želite li reći nešto kratko o ljubavi tokom naše ceremonije?" },
            choices: [
                {
                    value: "Yes",
                    text: { "default": "Yes", "sr": "Da" }
                },
                {
                    value: "No",
                    text: { "default": "No", "sr": "Ne" }
                },
            ],
            isRequired: false,
        },
        {
            name: "songs",
            type: "text",
            title: { "default": "Do you have any song requests? (We can’t guarantee we’ll be able to play all songs)", "sr": "Imate li kakvih zahtjeva za pjesme? (ne možemo garantovati da će svi zahtjevi biti ispunjeni)" },
            isRequired: false,
        }
    ]
}