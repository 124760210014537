export default {
    ourStory: [
        {
            image: "1.png",
            content: { 
                "en": "<small>(Dejan’s perspective)</small></br><h2>We met junior year of high school in our Forensics class. I had a crush on Alexia but we didn’t sit close to each other. Everytime we were doing a group project, she always picked me to be her partner. I would get very excited to get to know her better until she would say “My boyfriend and I..”. I assumed she wasn’t interested, but a week later she would pick me to be her partner again.</h2>", 
                "sr": "<small>(Dejanova perspektiva)</small></br><h2>Treće godinu srednje škole upoznali smo se na času forenzička nauka. Bio sam zaljubljen u Alexiju, ali nismo sjedili blizu. Svaki put kad bismo radili grupni projekt, uvijek me birala za partnera. Ja bih bio vrlo uzbuđen da je bolje upoznam dok ne kaže „Moj dečko i ja ..“. Pretpostavljao sam da nije zainteresirana, ali sledecu sedmicu ona bi me izabrala da joj ponovno budem partner.</h2>" 
            }
        },
        {
            image: "2.png",
            content: { 
                "en": "<small>(Alexia’s perspective)</small></br><h2>I always thought he was cute, so yes, I was picking him on purpose… Luckily, our senior year of high school we had AP Psychology class together and I was picking him to be my partner. THIS TIME I WAS SINGLE 😀 he even started stalking me.. I would go to the bathroom during a different class period and there he was…</h2>", 
                "sr": "<small>(Alexinova perspektiva)</small></br><h2>Uvijek sam mislila da je sladak, namerno sam ga birala ... Srećom, u našoj četvrta godina srednje škole imali smo zajedno psihologije i birala sam ga za svog partnera. OVAJ PUT NISAM IMALA MOMKA 😀  čak me počeo pratit .. Išla bih u WV za vrijeme drugog predavanja i eto ga ...</h2>" 
            }
        },
        {
            image: "3.png",
            content: { 
                "en": "<small>(Dejan’s perspective)</small></br><h2>It’s funny she says that, because she was stalking me. Everytime I went to the bathroom during class, there she was. Everytime I go to the community college, there she was. But to be honest, I was more flattered than worried 😉</h2>", 
                "sr": "<small>(Dejanova perspektiva)</small></br><h2>Smiješno je što to govori, jer je ona mene pratila. Kad god bih išao negje, tu je bila ona. Svaki put kad odem na fakultet, tu je bila. Ali da budem iskren, više sam bio polaskan nego zabrinut 😉</h2>" 
            }
        },
        {
            image: "4.png",
            content: { 
                "en": "<small>(Alexia’s perspective)</small></br><h2>…..anyways 🙄, he stalked me so much, he found and added me on facebook. I commented on his wall and we ended up talking on the facebook post. I woke up only to find an email of one of his comments saying something about asking for my number. I got to Facebook to respond… but the comment was DELETED.. GONE! I decided to message him my phone number anyway. </h2>", 
                "sr": "<small>(Alexinova perspektiva)</small></br><h2>… 🙄, toliko me pratio da je me pronašao na facebook. Komentirala sam na njegov zid i na kraju smo razgovarali na facebook postu. Kad sam se probudila, pronašla sam e-mail jednog od njegovih komentara u kojem piše nešto o traženju mog broja. Došla sam na Facebook da odgovorim ... ali komentar je IZBRISEN! Odlučila sam mu ipak poslati svoj telefonski broj.</h2>" 
            }
        },
        {
            image: "5.png",
            content: { 
                "en": "<small>(Dejan’s perspective)</small></br><h2>It’s true, I did delete the comment. But only because we were talking for a while and as soon as I said: “Can I have your phone number to stalk you in text too?” she stopped responding! How could she leave me on read with such a smooth pick-up line?!? It wasn’t until later that I found out she went to sleep right before I sent the message. </h2>", 
                "sr": '<small>(Dejanova perspektiva)</small></br><h2>Istina je, izbrisao sam komentar. Ali samo zato što smo neko vrijeme razgovarali i čim sam rekao: "Mogu li dobiti vaš broj telefona da vas i pratim preko teksta?" prestala je odgovoriti! Kako bi mogla da ne odgovori na tako fino pitanje?!? Tek kasnije sam saznao da je otišla spavati prije nego što sam poslao poruku.</h2>'
            }
        },
        {
            image: "6.png",
            content: { 
                "en": "<small>(Alexia’s perspective)</small></br><h2>As you can see, <i>I’m</i> clearly the one who initiated the relationship because, had I not seen the email, we literally may have never continued talking. Thankfully we did and we planned for our first date to be on Black Friday at the movies. We texted every day until Thanksgiving when he ended up asking me if I could hang out with him, his brother, and some friends to wait for Fry’s Electronics to open for the “deals” lol. We, then, had our first kiss in the Fry’s Electronic parking lot and I was up with them until 8am the next day. </h2>", 
                "sr": '<small>(Alexinova perspektiva)</small></br><h2>E baš sada vidite da sam ja taj koji je inicirala vezu, jer da nisam vidiela e-mail, možda nikada ne bismo nastavili razgovarati. Srećom jesmo i planirali smo da prvi sastanak bude na Crni petak u kinu. Pisali smo poruke svaki dan do Dana zahvalnosti kad me pitao mogu li se družiti s njim, njegovim bratom i nekim prijateljima da pričekam da se Fry\'s Electronics otvori za "ponude", hahaha. Tada smo se prvi put poljubili na parkingu Fry’s Electronica i bila sam s njima do 8 sljedećog jutro.</h2>'
            }
        },
        {
            image: "7.png",
            content: { 
                "en": "<small>(Dejan’s perspective)</small></br><h2>After all this time she should have learned I would have never given up that easy. 😉 But yes we did end up hanging out at Fry’s Electronics and a few other stores. Ironically, we never actually bought anything, we just use it as an excuse to hangout. Anyway, I made the first move and had a super romantic, not-at-all awkward, kiss in the parking lot of Fry’s Electronics. </h2>", 
                "sr": "<small>(Dejanova perspektiva)</small></br><h2>Nakon toliko vremena trebala je naučiti da nikada ne bih odustao tako lako. 😉 Ali da, na kraju smo se družili u Fry’s Electronicsu i nekoliko drugih trgovina. Ironično, nikada nismo ništa kupili, već je samo bio razlog da se družimo. U svakom slučaju, povukao sam prvi potez i imali smo super romantičan, nimalo neugodan 😀  poljubac na parkingu.</h2>" 
            }
        },
        {
            image: "8.png",
            content: { 
                "en": "<small>(Alexia’s perspective)</small></br><h2>We had an official “first date” at the movies the next day, where we saw “Love and Other Drugs” - we actually just watched this movie for only the 2nd time the week of our 10 year anniversary ❤️ A couple weeks later Dejan decided to ask me to be his girlfriend. At Build-A-Bear, they give you a “birth certificate” where you can pick the name of the bear. He named the bear “Will you go out with me?”. Every year since then Dejan has gotten me another Build-a-Bear as an anniversary gift… so yes we have a lot of stuffed animals now 😀</h2>", 
                "sr": "<small>(Alexinova perspektiva)</small></br><h2>Sutradan smo imali zvanični „prvi spoj“ u kino, gdje smo vidjeli „Ljubav i ostale droge“ - zapravo smo ovaj film gledali samo drugi put na našu desetu godišnjicu ❤️  Nekoliko sedmica kasnije Dejan je odlučio da me pita da mu budem djevojka. U Build-A-Bearu daju \"rodni list\" gdje može se napisati ime medvjediće. Nazao je medvjediću \"Hoćeš li izaći sa mnom?\". Od tada mi je Dejan na svaku godišnjicu poklonio još jedno medvjedića... i da, sada imamo dosta medvjedića.</h2>" 
            }
        },
        {
            image: "9.png",
            content: { 
                "en": "<small>(Dejan’s perspective)</small></br><h2>I showed up to our date wearing the most fashionable jacket ever. I might even attribute that tailored jacket to the reason Alexia fell in love with me. (It was actually my brother’s jacket that was wayyyy to small). I knew pretty early on that I would ask Alexia to be my girlfriend. What I didn’t know is how much money I would end up spending on teddy bears after all these years.</h2>", 
                "sr": "<small>(Dejanova perspektiva)</small></br><h2>Na našeg sastanka pojavio sam se u najmodernijoj jakni ikad. Mogao bih čak pripisati tu skrojenu jaknu razlogu zašto se Alexia zaljubila u mene. (A istina je bila da je jakna mog brata i bila je pre mala meni). Prilično sam rano znao da ću zamoliti Alexiju da mi bude djevojka. Ono što nisam znao je koliko bih novca potrošio na medvjediće nakon svih ovih godina.</h2>" 
            }
        },
        {
            image: "10.png",
            content: { 
                "en": "<small>(Alexia’s perspective)</small></br><h2>A short 3 months later, I turned around on my way home because I left something at his house and in the driveway of his house we said I love you to each other for the first time 😀</h2>", 
                "sr": "<small>(Alexinova perspektiva)</small></br><h2>Poslje 3 Kratka mjeseci okrenuo sam se na putu kući jer sam ostavio nešto kod njega i na prilazu njegove kuće rekli smo za prvi put da se volimo 😀</h2>" 
            }
        },
        {
            image: "11.png",
            content: { 
                "en": "<small>(Dejan’s perspective)</small></br><h2>When she says that “we” said I love you to each other, what she really means is that <strong><i>I</i></strong> said I love you to her and then she started crying. After what felt like an eternity, she told me “I love you too”</h2>", 
                "sr": "<small>(Dejanova perspektiva)</small></br><h2>Kad kaže da smo „mi“ rekli da se volimo, ona zapravo hoće da kaže da sam <strong><i>ja</i></strong> joj rekao da je volim i tada je počela plakati. Nakon što je meni osjećalo kao godnie, on je mi rekla \"I ja tebe volim\"</h2>" 
            }
        },
        {
            image: "12.png",
            content: { 
                "en": "<h2>We are so excited for you to all be a part of our story on November 13th, 2021.</h2>", 
                "sr": "<h2>Jedva čekamo da i vi budete dio naše priče na 13. novembra 2021.</h2>" 
            }
        }
    ],
    rsvp: {
        alertMessage: {
            "en": "Please note that the wedding will take place outdoors in the desert with rocky and sandy floor.", 
            "sr": "Venčanje će se održati u kamenitom i peščanom području." 
        }
    },
    dynamicHomepage: [
        {
          components: [
            { name: "Hero" },
            { name: "Gallery" },
          ],
          start: "Jan 1 1900",
          end: "Nov 3 2021",
        },
                {
          components: [
            { name: "MapAndDate" },
            { name: "Transportation" },
            {
              name: "Custom",
              props: {
                title: {
                    "en": "Additional Detials",
                    "sr": "Detalji"
                },
                content: {
                    "en": "The wedding is outside and on flat desert. We strongly recommend you do not wear heels. You know yourself best so consider your needs for the night and of course bring a change of shoes if you’d like.</br></br>The shuttle will break from 4:40-5:00PM for the ceremony</br></br>We expect there may be issues with Uber and Lyft when guests are leaving simply because getting a ride recently has been challenging in general. If you plan to use Uber or Lyft, schedule your ride beforehand to make sure you have a ride home. We are going to start turning down the music at around midnight but this does not mean you have to leave by then.",
                    "sr": "Svadba se održava u kamenitom i peščanom području. Preporučujemo da se ne nose štikle. </br></br>Od 16:30 do 17:00 Kombi će biti zaustavljen za ceremoniju</br></br>Očekujemo da će možda biti problema s Uberom i Lyftom kada gosti odlaze. Ako planirate koristiti Uber ili Lyft, unaprijed zakažite svoja kola da biste bili sigurni da imate prijevoz do kuće. Počećemo snižavati muziku oko 00:00, ali to ne znači da morate ići kući tada."
                }
              },
            },
          ],
          start: "Nov 3 2021",
          end: "Nov 16 2021",
        },
        {
            components: [
              { name: "Hero" },
            ],
            start: "Nov 16 2021",
            end: "Nov 14 2029",
          },
      ],
}